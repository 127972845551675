









































































































import { Component, Vue } from "vue-property-decorator";
import {
	RedCapital,
	SelectedCountry,
	Countries,
	TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";
import { mapState } from "vuex";
import TooltipButton from "@/components/TooltipButton.vue";
import TooltipDropdown from "@/components/TooltipDropdown.vue";
import TableResponsive from "@/components/table/TableResponsive.vue";
import ShowLoading from "@/components/panel/ShowLoading.vue";
import { EventBus } from "@/main";
import BusinessSelect from "@/components/dashboard/BusinessSelect.vue";

@Component({
  computed: {
    ...mapState(["user", "business", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  metaInfo: {
    title: "Mis empresas",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
 data() {
    return {
      selector: [
        {
          name: "Factoring",
          id: 0,
          estado: false
        },
        {
          name: "Crédito",
          id: 1,
          estado: false
        },
        {
          name: "Crédito sin garantía (Recomendado para Microempresas",
          id: 2,
          estado: false
        },
      ],
      canShow:false,
      RedCapital,
      SelectedCountry,
      search: "",
      showTooltip: false,
      businessData: null,
      showMore: false,
      showId: null,
      regions: null,
      communes: null,
      tablaEmpresas: {
				headers: [
					{
						// @ts-ignore
						text: `${RedCapital[SelectedCountry].DniName} Empresa`,
						align: "center",
						value: "rut",
					},
					{
						text: "Razon social",
						align: "center",
						value: "razon_social",
					},
					{
						text: "Telefono",
						align: "center",
						sortable: false,
					},
					{
						text: "Editar",
						align: "center",
						sortable: false,
					}
				],
				msjEmpty: 'No existen registros en tu cuenta',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
			},
      headers: [
        {
          // @ts-ignore
          text: `${RedCapital[SelectedCountry].DniName} Empresa`,
          value: "rut"
        },
        {
          text: "Razon social",
          value: "razon_social"
        },        
        {
          text: "Telefono",
          value: "telefono"
        },
        {
          text: "Editar",
          align: "center",
          sortable: false
        }
      ],
      staticCountries: Countries,
      countries: null
    };
  },
  async beforeMount() { // TODO: Cuando tenga tiempo cambiar forma de cargar el dataTable para darle la interaccion al btn seleccionar
		// @ts-ignore
		this.$data.businessData = await API.getBusiness();
		this.$data.countries = await API.getCountries();
    console.log(this.$data.businessData)
		/* Formateando Data */
		let propsObjTableAll = {
			style: `text-align: center;
					font-size: 0.7rem;
					font-weight: 500;
					color: #8b8b8b
          heigth: 10vh`
		}
		let propsObjTableButtons = {
			style: `display: flex;
					justify-content: center;`
		}
		Array.from(this.$data.businessData, business =>{
			// @ts-ignore
			business.datosEnTabla;	
      
			let objTable = {
				rutEmpresa:{
					// @ts-ignore
					value: business.rut,
					label: 'RUT Empresa',
					props: propsObjTableAll
				},
				razonSocial:{
					// @ts-ignore
					value: business.razon_social,
					label: 'Razon social',
					props: propsObjTableAll
				},
				telefono:{
					// @ts-ignore
          value: business.telefono,
          label: 'Telefono',
          props: propsObjTableAll,
				},
				acciones:{
					// @ts-ignore
					value: '',
					label: 'Acciones',
					props: propsObjTableButtons,
					button: {
						funcion: 'solicitanteMisEmpresas_editChild',
						icoFont: 'create',
						notOutline: true,
						// @ts-ignore
						data: business.id
					},
				},
			}
			//@ts-ignore
			business.datosEnTabla = objTable
		})

		this.$data.canShow = true;
	},
  methods: {
    showSeleccionar(test){
      console.log(test)
    },
    edit(id: number) {      
      this.$router.push({
        path: `/panel/editar-empresa/${id}`
      });
    },
    select(data){
      console.log(data);
      switch(data) {
        case 0:{
          this.$router.push({
            path: "/panel/solicitar/factoring"
          });
          break;
        }
        case 1:
          this.$router.push({
            path: "/panel/solicitar/credito"
          });
          break;
        case 2:
          this.$router.push({
            path: "/conocerte"
          });
          break;
        default:
      }
      
    },

    request(id: number) {
      this.$store.commit("setBusiness", id);
    },
    getBusinessById(id: number) {
      return this.$data.businessData.find((el: any) => el.id == id);
    },
    getRegionText(regionId: number) {
      return regionId == null
        ? "Desconocido"
        : this.$data.regions.find((el: any) => el.id == regionId);
    },
    getCommuneText(communeId: number) {
      return communeId == null
        ? "Desconocido"
        : this.$data.communes.find((el: any) => el.id == communeId);
    },
    goToAction() {
      this.$store.commit("deleteSimulation");
      this.$router.push({
        path: "/conocerte"
      });
    }
  },
  components: {
		TooltipButton,
		TooltipDropdown,
		TableResponsive,
		ShowLoading
	},
})
export default class Business extends Vue {}
